import * as React from "react";
import { graphql } from "gatsby";
import IndexPageComponent from "src/components/pages";

const IndexPage = ({ data, location }) => {
  const copy = {
    bannerText: "ספטנר ביטוח חיים",
    video: {
      title: "ברוכים הבאים",
      text: (
        <span>
          חברת ספטנר הינה חברה וותיקה בשוק הביטוחים האמריקאי, החברה מספקת
          ביטוחים מגוונים לישראלים אשר קיים להם קשר בארה”ב, לחברה הבנה עמוקה
          בשוק הביטוחים, נסיון ומקצוענות, <b>כאן תוכלו לקבל ייעוץ ללא עלות</b>.
        </span>
      ),
    },
    quoteButton: "קבל הצעת מחיר",
    readMore: "קרא עוד",
    homeMainItems: [
      {
        title: "מדוע לקנות ביטוח חיים",
        text: `ביטוח חיים שומר מפני הפסד כלכלי שעלול להיוותר ללא מענה לאחר פטירתו של המבוטח. הנה כמה דוגמאות לצרכים שהגנה של ביטוח חיים יכולה לתת להם מענה.`,
        href: `/learn/who-needs-life-insurance`,
      },
      {
        title: 'בין ארה"ב לישראל',
        text: `ביטוחי החיים הנמכרים כיום בישראל נחותים בהרבה מאלו הנמכרים בארה”ב, הן מצד המחיר והן מצד איכות המוצר. בהשוואה לתושב אמריקאי שיכול להנות מביטוחי חיים ריסק/תקופתי במחירים משתלמים מאוד עם חוזים שמבטיחים מחיר קבוע שאינו עולה עם השנים לתקופות של 10/15/20/25 ואפילו 30 שנה, תושב ישראלי בריא באותה מידה משלם עבור אותה הפוליסה פרמיה גבוהה פי 3 – 4 והמחיר שלה עולה בכל שנה.`,
        href: `/learn/why-buy-us-life-insurance`,
      },
    ],
    testimonials: {
      title: "המלצות",
      items: [
        {
          author: "ד”ר מיכאל גולדנהירש",
          text: `התחלנו עם האבא והמשכנו עם הבן יונתן​ הוא המשיך את המסורת של האבא שזה אנשים מאד נחמדים, נאמנים ואפשר לסמוך עליהם.`,
        },
        {
          author: "צבי סנד",
          text: `מאד התרשמתי מהידע שלו והיכולות שלו; כל שאלה שהעלתי אליו…מיד מענה.`,
        },
        {
          author: "צ'רלי דויטש",
          text: `כל אדם ה מתגורר באיזור סנט לואיס מכיר היטב את השם ״ספטנר״, זוהי מסורת של שירות ונתינה…`,
        },
      ],
    },
    blogPosts: {
      title: "קרא עוד על ביטוחים",
      items: data.allFile.nodes.map((node) => ({
        title: node.childMdx.frontmatter.title,
        preview: node.childMdx.excerpt,
        href: `/blog/${node.childMdx.slug}`,
      })),
    },
    insuranceQuotes: {
      title: "הצעות מחיר לביטוח חיים",
      text: `רוצה הצעה אטרקטיבית לביטוח חיים אמריקאי?`,
    },

    contact: {
      title: "צור קשר",
      fields: {
        name: "שם מלא",
        phone: "טלפון",
        email: 'דוא"ל',
        message: "הודעה",
      },
      success: (
        <span>
          תודה על פנייתך.
          <br />
          נציגינו יחזרו אליכם בהקדם.
        </span>
      ),
      error: "אירעה שגיאה. אנא נסו שנית במועד מאוחר יותר.",
      button: "שלח",
    },
  };

  return (
    <IndexPageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="בית"
      pageName="home"
      bannerText={copy.bannerText}
    ></IndexPageComponent>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        childMdx: { id: { ne: null } }
        sourceInstanceName: { eq: "he/blog" }
      }
      limit: 3
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          excerpt(truncate: true)
          slug
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
